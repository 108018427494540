import { useState } from 'react';
import {
  Button,
  Form,
  Separator,
  TooltipTrigger,
  Tooltip,
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
} from 'react-aria-components';

import { Icon } from '~/components/icon';
import { AffinityNoCache, OpenAILogo, SecurityIcon, SecurityWhiteIcon } from '~/components/ai/icons';
import { twMerge } from 'tailwind-merge';
import { PrimaryNavLinkButton } from '~/components/primary-nav-link-button';

export default function AIRunnerPage() {
  const [showAuthorizeModal, setShowAuthorizeModal] = useState(false);

  return (
    <main>
      <div className="relative mb-4 flex items-center justify-center py-[16vh] pt-[10vh] text-gray-900">
        <div className="relative flex w-full flex-col items-center gap-6 px-6 text-center">
          <div className="flex w-full flex-col items-center gap-3">
            <h2 className="text-[48px] font-[800] leading-[58px] sm:text-5xl [@media(max-width:480px)]:text-[2rem]">
              Accelerate your AI
            </h2>
            <p className="text-[18px] font-[400] leading-[26px] text-black/50">
              Semantically cache and secure all your LLM traffic.
            </p>
          </div>
          <div className="z-10 m-auto flex w-full flex-col divide-zinc-600 rounded-[8px] bg-[#151544] shadow-lg shadow-black/40 sm:max-w-[600px]">
            <Form className="relative z-10 h-full w-full min-w-0 rounded-[8px] bg-[#151544] p-2">
              <div className="relative flex w-full flex-1 flex-col items-center gap-3 rounded-md bg-[#151544] transition-all duration-300">
                <div className="relative flex w-full flex-col justify-between gap-4 p-2">
                  <div className="flex flex-1 items-center gap-1 border-b border-black/30 sm:gap-2">
                    <Button
                      className="focus-visible:ring-ring flex h-8 w-fit flex-1 shrink-0 select-none items-center justify-center gap-2 whitespace-nowrap rounded-md bg-[#151544] px-2 py-2 text-sm font-medium text-white transition-colors hover:bg-[#151544] hover:bg-opacity-70 focus-visible:bg-[#151544] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50 sm:w-32 sm:px-3"
                      type="button"
                      onPress={() => setShowAuthorizeModal(true)}
                    >
                      <div className="flex items-center gap-2">
                        <OpenAILogo />
                        <div className="text-[13px] font-normal">OpenAI</div>
                        <Icon icon="chevron-down" className="h-2.5 w-2.5 text-white/70" />
                      </div>
                    </Button>

                    <Separator className="h-[20px] w-[1px] border-0 bg-white/20" />
                    <Button
                      className="focus-visible:ring-ring flex h-8 w-fit flex-1 shrink-0 select-none items-center justify-center gap-2 whitespace-nowrap rounded-md bg-[#151544] px-2 py-2 text-sm font-medium text-white transition-colors hover:bg-[#151544] hover:bg-opacity-70 focus-visible:bg-[#151544] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50 sm:w-44 sm:px-3"
                      type="button"
                      onPress={() => setShowAuthorizeModal(true)}
                    >
                      <div className="flex items-center gap-2">
                        <AffinityNoCache />
                        <div className="text-[13px] font-normal">No cache</div>
                        <Icon icon="chevron-down" className="h-2.5 w-2.5 text-white" />
                      </div>
                    </Button>
                  </div>
                  <div className="flex w-full items-center justify-center">
                    <div className="flex w-full max-w-[90%] items-center justify-between gap-2 sm:max-w-[500px]">
                      {/* <Button
                        className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] focus-visible:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                        type="button"
                        onPress={() => setShowAuthorizeModal(true)}
                      >
                        <div className="flex items-center gap-2">
                          <Icon icon="lock-open" className="h-3 w-3" />
                          <div className="hidden text-[13px] font-medium sm:block">Client Auth</div>
                          <Icon icon="chevron-down" className="h-3 w-3" />
                        </div>
                      </Button> */}

                      <TooltipTrigger delay={0}>
                        <Button
                          className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white/30 focus-visible:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 sm:min-w-[100px]"
                          type="button"
                          onPress={() => setShowAuthorizeModal(true)}
                        >
                          <div className="flex items-center gap-2">
                            <Icon icon="clock" className="h-3 w-3" />
                            <div className="hidden text-[13px] font-medium sm:block">60s Cache TTL</div>
                            <Icon icon="chevron-down" className="h-3 w-3" />
                          </div>
                        </Button>
                        <Tooltip
                          offset={17}
                          placement="bottom"
                          className="flex flex-col overflow-y-auto rounded-md bg-[#151544] px-3 py-2 text-[14px] text-sm text-white/60 shadow-[3.0px_3.0px_5.0px_rgba(0,0,0,0.38)] focus:outline-none"
                        >
                          Enable cache to change TTL
                        </Tooltip>
                      </TooltipTrigger>
                      <Button
                        className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                        type="button"
                        onPress={() => setShowAuthorizeModal(true)}
                      >
                        <div className="flex items-center gap-2">
                          <SecurityWhiteIcon />
                          <div className="hidden text-[13px] font-medium sm:block">No guardrails</div>
                          <Icon icon="chevron-down" className="h-3 w-3" />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute -right-[20px] top-1/2 -translate-y-1/2">
                <Button
                  className="flex h-[50px] w-[50px] shrink-0 items-center justify-center whitespace-nowrap rounded-full border-4 border-[#151544] bg-[#292984] text-sm font-medium text-white shadow-[3.0px_3.0px_5.0px_rgba(0,0,0,0.38)] transition-colors hover:bg-[#4000BF] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none"
                  type="button"
                  onPress={() => {
                    setShowAuthorizeModal(true);
                  }}
                >
                  <span className="sr-only">Send</span>
                  <Icon icon="chevron-right" className="h-[18px] w-[18px] text-white" />
                </Button>
              </div>
            </Form>
          </div>
          <div className="absolute top-full mx-auto mt-3 flex w-full flex-wrap items-center justify-center gap-3 whitespace-nowrap px-2 text-sm sm:mt-4 sm:max-w-[600px] sm:justify-between sm:gap-8">
            <div className="flex gap-4">
              {/* <Button className="inline-flex select-none items-center gap-1 whitespace-nowrap rounded-full border-0 px-1 text-[13px] text-black/65 transition-colors hover:opacity-60 focus:outline-none">
                Watch demo
                <ArrowUpRight />
              </Button> */}
            </div>
            <div className="flex items-center gap-2">
              <SecurityIcon />
              <span className="text-[13px] font-normal text-[#4000BF]">
                Traffic is processed and secured by Kong Konnect.
              </span>
            </div>
          </div>
        </div>
      </div>
      <AuthorizeModal isOpen={showAuthorizeModal} onClose={() => setShowAuthorizeModal(false)} />
    </main>
  );
}

type AuthorizeProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function AuthorizeModal({ isOpen, onClose }: AuthorizeProps) {
  return (
    <ModalOverlay
      isDismissable
      isOpen={isOpen}
      aria-label="modal"
      className={({ isEntering, isExiting }) =>
        twMerge(
          'fixed inset-0 z-10 flex min-h-full items-center justify-center overflow-y-auto bg-[#00000080] p-4 text-center',
          isEntering ? 'duration-300 ease-out animate-in fade-in' : '',
          isExiting ? 'duration-200 ease-in animate-out fade-out' : '',
        )
      }
    >
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={({ isEntering, isExiting }) =>
          twMerge(
            'w-full max-w-md overflow-hidden rounded-[4px] bg-white text-left align-middle shadow-xl',
            isEntering ? 'duration-300 ease-out animate-in zoom-in-95' : '',
            isExiting ? 'duration-200 ease-in animate-out zoom-out-95' : '',
          )
        }
      >
        <Dialog role="alertdialog" className="relative m-0 outline-none">
          {({ close }) => (
            <div className="flex h-full flex-col">
              <div className="flex items-center justify-between px-6 pb-2 pt-4">
                <Heading slot="title" className="my-0 text-[16px] font-[500] leading-6 tracking-[-0.25px] text-black">
                  Sign in to Insomnia
                </Heading>

                <Button
                  className="p-2 text-slate-500 hover:text-slate-600"
                  onPress={() => {
                    onClose();
                    close();
                  }}
                >
                  <Icon icon="xmark" className="h-4 w-4 cursor-pointer text-[#00000066]" />
                </Button>
              </div>
              <div className="flex-1 overflow-y-auto p-6 pt-3">
                <div className="text-slate-500">
                  <div className="flex flex-col">
                    <p className="m-0 text-sm font-normal leading-[18px] tracking-[-0.25px] text-black/85">
                      An Insomnia account is required to use AI Runner.
                    </p>
                  </div>
                  <PrimaryNavLinkButton to="/app/authorize?redirect=/ai" className="mt-4 w-[180px]">
                    Login / Sign up for free
                  </PrimaryNavLinkButton>
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
